body,
select {
    font-family: sans-serif;
    font-size: 14px !important;
}

.font-normal {
    font-weight: normal;
}

.font-bold {
    font-weight: bold;
}

.vital-sign-date {
    background-color: #909090;
    color: white;
    border-radius: 15px;
    padding: 3px 10px;
    margin-left: 10px;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.text-transform {
    text-transform: none !important;
}

.background-whitesmoke {
    background-color: whitesmoke;
}

.assessment-tab .MuiTabs-vertical {
    background-color: #e6e0db;
    color: gray;
}

.assessment-tab .MuiButtonBase-root {
    border-bottom: 1px solid white;
}

.assessment-tab .MuiTab-root {
    max-width: none !important;
    align-items: initial;
}

.assessment-tab .MuiTab-textColorInherit.Mui-selected {
    opacity: 1;
    background-color: #9e948d;
    color: white;
    border-bottom: 3px solid #0063a7;
}

.assessment-tab .MuiTab-textColorInherit.Mui-selected .MuiSvgIcon-root {
    display: block;
    font-size: 15px;
    margin-top: 4px;
    margin-right: 2px;
}

.assessment-tab .MuiTabs-fixed .PrivateTabIndicator-colorSecondary-3 {
    background-color: transparent;
}

.assessment-tab .MuiTab-wrapper {
    padding-left: 10px;
    display: -webkit-inline-box;
}

.assessment-tab .MuiTab-labelIcon {
    min-height: auto !important;
}

.assessment-tab .menu-item {
    display: flex;
    text-align: initial;
    align-items: center;
}

.assessment-tab .Mui-selected {
    background-color: #9e948d;
    color: white !important;
    border-bottom: 3px solid #0063a7;
}

.assessment-tab .arrow-icon {
    display: none;
}

.assessment-tab .Mui-selected .arrow-icon {
    display: block;
}

.assessment-tab .menu-error {
    background-color: #d73d32 !important;
    color: white !important;
    border-bottom: none !important;
}

.assmnt-tab-panel-container .content-panel {
    background-color: white;
    padding-top: 10px;
}

.assmnt-tab-panel-container .content-panel hr:not(.MuiDivider-root) {
    border-top: 2px solid #191919;
}

.assmnt-tab-panel-container .content-panel .content-form {
    padding: 0 20px;
    min-height: 100px;
}

.assmnt-tab-panel-container .content-panel .content-form .loader-container {
    min-height: 250px;
    align-items: center;
}

.assmnt-tab-panel-container .content-footer,
.assmnt-tab-panel-container .content-footer.MuiGrid-item {
    display: flex;
    background-color: #adadadb5;
    padding: 0;
    margin-top: 15px;
    justify-content: flex-end;
}

.assmnt-tab-panel-container .content-panel .MuiButton-containedSecondary {
    background-color: #909090;
}

.assmnt-tab-panel-container .content-panel .MuiButton-containedSecondary:hover {
    background-color: #676767;
}

.pl-2 {
    padding-left: 8px !important;
}

.pl-4 {
    padding-left: 16px !important;
}

.member-detail {
    padding: 16px;
}

.assmnt-tab-panel-container .consent-form .MuiFormLabel-root {
    width: 25%;
    min-width: 300px;
    margin-right: 20px;
}

.upload-btn {
    min-width: 175px;
}

.upload-btn .upload-btn-file-name {
    position: absolute;
    width: 100%;
    padding: 6px 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.emoji {
    border-radius: 10px;
    background-color: #ffff00a3;
}

.hr-lite {
    border-top: 1px solid #e0e0e0 !important;
}

.no-answer-text {
    color: #bbbbbb !important;
}

.initials-div {
    position: absolute;
    margin-top: -160px;
}
