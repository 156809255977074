.member-info {
    line-height: 16px;

    padding: 1em;
}

.member-info-label {
    text-align: left;
    font-style: italic;
}

.member-info-detail {
    font-weight: bold;
}

.priority-one {
    background-color: #dc3545;
    width: 100%;
    text-align: left;
    color: white;
    height: 100%;
    padding-top: 9px;
    padding: 1em;
}

.priority-two {
    background-color: #eb984e;
    width: 100%;
    text-align: left;
    color: white;
    height: 100%;
    padding-top: 9px;
    padding: 1em;
}

.button-panel-btn {
    width: 100%;
    padding: 0.5em;
}

.button-panel-detail {
    height: 100%;
    padding: 0.5em;
}

.documentation-detail {
    font-size: 12px;
}
