body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.env-text {
    position: absolute;
    font-size: 120px!important;
    font-weight: bold!important;
    top: 15%;
    Color: #c05a64;
    opacity: .5;
    transform: rotate(20deg);
}

.login-panel {
    min-width: 350px; 
    padding-top: 40px;
}

.login-panel-bottom {
    color: grey !important; 
    font-size: 13px !important;
}

.app-version{
    margin-top: 50px !important;
}

@media screen and (max-width: 600px) {
    .login-page .MuiStack-root{
        padding-top: 0;
        padding-bottom: 0;
    }

    .env-text {
        font-size: 50px!important;
        top: 10%;
    }

    .login-image {
        width: 200px;
        margin-top: 0px !important;
    }

    .login-panel {
        padding-top: 10px !important;
    }

    .app-version{
        margin-top: 20px !important;
    }
  }

.b-toolbar.b-dock-top,
.b-toolbar.b-dock-bottom {
    min-height: 3.8em !important;
}

.b-toolbar .b-toolbar-content {
    padding: 0em 0em !important;
    max-height: 50px !important;
}

.colortrue {
    color: red !important;
}

.colorerror {
    color: red !important;
}

.colorsuccess {
    color: green !important;
}

.fab-right {
    float: right !important;
    cursor: pointer;
    margin-left: 16px !important;
    min-width: 145px !important;
}

.claim-number-cell {
    color: #1565c0;
    cursor: pointer;
    margin-right: 5px;
}

.claim-number-cell:hover {
    color: blue;
}

.closed-status-color {
    color: #bbb !important;
}
