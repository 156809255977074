.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.p-1 {
    padding: 1px !important;
}

.p-10 {
    padding: 10px !important;
}

.p-15 {
    padding: 15px !important;
}

.pb-50 {
    padding-bottom: 50px !important;
}

.no-pt {
    padding-top: 0 !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.m-10 {
    margin: 10px !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.mr-2 {
    margin-right: 8px !important;
}

.mr-3 {
    margin-right: 12px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.d-flex {
    display: flex !important;
}

.d-flex-row {
    display: flex !important;
    flex-direction: row !important;
}

.d-row-center {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}

.d-flex-center {
    display: flex !important;
    align-items: center !important;
    justify-content: center;
}

.d-row-space-between {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.gray-hr {
    border-top-color: #cccbcb !important;
}

.fill {
    flex: 1;
}

.no-b-border {
    border-bottom-width: 0 !important;
}

.relative {
    position: relative !important;
}

.pointer-events-none {
    pointer-events: none;
}

.text-transform-none {
    text-transform: none !important;
}

.error-date-input {
    border-color: red;
}

.MuiLink-root {
    cursor: pointer;
}

.hide-textinput-label fieldset {
    top: 0;
}

.hide-textinput-label fieldset legend {
    display: none;
}

.sm-textarea .MuiInputBase-root {
    padding: 10px;
}

.sm-textarea .MuiInputBase-root textarea {
    font-size: 14px;
}

.x-small-text-input,
.x-small-text-input.MuiOutlinedInput-root {
    height: 32px;
    font-size: 14px;
}

.x-small-text-input fieldset {
    top: 0;
}

.x-small-text-input .MuiInputBase-input {
    padding: 5.5px 12px;
}

.initials-input {
    max-width: 55px;
}

.no-data-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60px;
    font-size: 14px;
    color: #666;
}

.MuiFormLabel-asterisk {
    color: red;
}

.button-120 {
    min-width: 120px !important;
    margin-bottom: 16px !important;
}

.button-120-nomb {
    min-width: 120px !important;
}

.icon-left {
    cursor: pointer;
}

.icon-right {
    cursor: pointer;
    margin-left: 16px;
}

.mb-default {
    margin-bottom: 16px !important;
}

.mt-default {
    margin-top: 16px !important;
}

.pointer {
    cursor: pointer;
}

.profile-text {
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.87);
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
}

.profile-image {
    border: solid;
    border-color: #1976d2;
    box-shadow: 0 0 4px #1976d2;
    border-radius: 5% !important;
}

.width-100p {
    width: 100% !important;
}

.form-control-200 {
    width: 200px !important;
}

.float-right {
    float: right !important;
}

.float-left {
    float: left !important;
}

.smartdashboard-widget {
    padding: 16px;
    margin: 8px;
    box-sizing: border-box;
}

.scroll-overflow {
    overflow-y: scroll;
}

.MuiAlert-root.MuiAlert-standardError {
    margin-top: 60px;
}

.large-modal-box {
    width: 95%;
    height: 95%;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    margin-top: 16px;
    margin-bottom: 16px;
    padding-top: 16px;
}

.highlight-member {
    background-color: lightgreen;
}

.icon-claim-history {
    padding-left: 16px;
    font-size: 40px!important; 
    font-weight: bold!important; 
    color: green; 
    cursor: pointer;
}

.claim-history-cell {
    min-width: 125px!important;
}

.claim-history-label {
    min-width: 125px!important; 
}

@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }
    .member-name {
        display: block !important;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
