.loader-center {
    z-index: 100;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}